import { DateSelect } from "@Components/Graph/components/DrawerGraphFilter";
import { GraphEmptyState } from "@Components/Graph/components/GraphEmptyState";
import {
  GenericBox,
  HeaderGraph,
  GraphTwoBarsAndLine,
} from "@Components/index";

export const HeadcountEvolution = ({
  data,
  onFilterChange,
  testCompanyId,
}: {
  data: {
    info: { title: string; metric: string; explanation: string };
    data: any;
    settings: any;
    filters?: {
      dateTo?: string;
      dateFrom?: string;
    };
  };
  onFilterChange: (date: DateSelect | undefined) => void;
  testCompanyId?: string;
}) => {
  const info = data.info;
  const graphData = data.data;
  const settings = data.settings;
  const filters = data.filters;

  const isEmpty = graphData?.every(
    (info: any) => info.bar1 == 0 && info.bar2 == 0 && info.line == 0
  );

  return (
    <GenericBox size={"medium"}>
      <HeaderGraph
        info={info}
        filters={filters}
        onFiltersChange={onFilterChange}
        reportName="HEADCOUNT_EVOLUTION"
        testCompanyId={testCompanyId}
        disabled={isEmpty}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphTwoBarsAndLine data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
