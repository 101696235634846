import { SmallNumberInfo } from "@Components/Graph/components/SmallNumberInfo";
import { GenericBox } from "@Components/index";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

export const HeadcountTotalAdmission = ({
  data,
}: {
  data: {
    title: string;
    value: number;
    icon: IconTypes;
    explanation: string;
  };
}) => {
  const info = { ...data };

  return (
    <GenericBox size="small" flex={true}>
      <SmallNumberInfo info={info} />
    </GenericBox>
  );
};
