import { useState } from "react";
import {
  SearchField,
  Checkbox,
  Tab,
  Button,
  IconButton,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  ModalContainer,
  ModalTitle,
  ModalSubTitle,
  BoxContainer,
  PeopleAndGroupTitle,
  PeopleAndGroupSubTitle,
  SearchContainer,
  CheckboxItem,
  CheckboxLabel,
  AmountLabel,
  PositionRow,
  PositionColumn,
  ToggleTitle,
  ToggleSubTitle,
  BoxTitle,
  ButtonContainer,
  StyledModal,
  Container,
} from "./styled";
import { PreparedPalettes } from "./PreparedPalettes";

export const InsightsModal = ({ isOpen, closeModal, config }: any) => {
  const [defaultTab, setDefaultTab] = useState(0);

  const tabItens = [
    // {
    //   label: "Personalizado",
    //   component: <CustomColors />,
    // },
    {
      label: "Paletas prontas",
      component: <PreparedPalettes />,
    },
  ];

  return (
    <StyledModal
      open={isOpen}
      onClose={() => closeModal()}
      headerSubTitle="Exemplo de Subtitulo"
      headerTitle="Exemplo de titulo"
    >
      <Container>
        <ModalContainer>
          <ModalTitle variant="headline6">Configurações de Insights</ModalTitle>
          <ModalSubTitle variant="body3">
            Defina como o seu dashboard deve se comportar
          </ModalSubTitle>

          <BoxContainer>
            <PeopleAndGroupTitle variant="body3">
              Selecione quais grupos de pessoa devem ser considerados nos
              insights
            </PeopleAndGroupTitle>
            <PeopleAndGroupSubTitle variant="body4">
              Adições ou remoções de pessoas nos grupos refletem imediatamente
              nos cálculos.
            </PeopleAndGroupSubTitle>

            <SearchContainer>
              <SearchField label="Buscar" options={[]} />
            </SearchContainer>

            {config?.groups?.map((g: any, i: any) => {
              return (
                <CheckboxItem key={i}>
                  <Checkbox />
                  <CheckboxLabel variant="body3">{g.name}</CheckboxLabel>
                  <AmountLabel variant="body4">{g.amt} pessoas</AmountLabel>
                </CheckboxItem>
              );
            })}
          </BoxContainer>

          <BoxContainer>
            <PositionRow>
              <Toggle />
              <PositionColumn>
                <ToggleTitle variant="body3">
                  Considerar nos insights de Admissão pessoas cadastradas
                  diretamente na lista de colaboradores
                </ToggleTitle>
                <ToggleSubTitle variant="body4">
                  Desmarque essa opção para considerar apenas pessoas
                  adicionadas pelos Fluxos de Colaborador
                </ToggleSubTitle>
              </PositionColumn>
            </PositionRow>
          </BoxContainer>

          <BoxContainer>
            <PositionRow>
              <Toggle />
              <PositionColumn>
                <ToggleTitle variant="body3">
                  Considerar nos insights de Desligamento pessoas excluídas da
                  lista de colaboradores
                </ToggleTitle>
                <ToggleSubTitle variant="body4">
                  Desmarque essa opção para considerar apenas pessoas desligadas
                  pelos Fluxos de Colaborador
                </ToggleSubTitle>
              </PositionColumn>
            </PositionRow>
          </BoxContainer>

          <BoxContainer>
            <BoxTitle variant="body3">Cores dos gráficos</BoxTitle>
            <ToggleSubTitle variant="body4" style={{ marginBottom: "32px" }}>
              Personalize a paleta de cores do seu dashboard com a cor da sua
              marca, ou combinações prontas.
            </ToggleSubTitle>

            <Tab
              tabItens={tabItens}
              selected={defaultTab}
              onTabChanged={(selected) => setDefaultTab(selected)}
            />
          </BoxContainer>

          <ButtonContainer>
            <Button size="small" variant="primary" onClick={closeModal}>
              Salvar
            </Button>
          </ButtonContainer>
        </ModalContainer>
        <IconButton
          icon="IconX"
          onClick={closeModal}
          variant="line"
          size="small"
        />
      </Container>
    </StyledModal>
  );
};
