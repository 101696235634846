import { GraphEmptyState } from "@Components/Graph/components/GraphEmptyState";
import {
  GenericBox,
  HeaderGraph,
  GraphPieChartEmptyCircle,
  TGraphPieChartEmptyCircle,
} from "@Components/index";

export type THeadcountByGender = {
  data: {
    info: { title: string; metric: string; explanation: string };
    data: TGraphPieChartEmptyCircle["data"];
    settings: TGraphPieChartEmptyCircle["settings"];
  };
  testCompanyId?: string;
};

export const HeadcountByGender = ({
  data,
  testCompanyId,
}: THeadcountByGender) => {
  const info = data.info;
  const graphData = data.data;
  const settings = data.settings;
  const isEmpty = !graphData.length;

  return (
    <GenericBox size="custom">
      <HeaderGraph
        info={info}
        reportName="GENDER"
        testCompanyId={testCompanyId}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphPieChartEmptyCircle data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
