import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";
import { HTMLAttributes } from "react";

interface ITagNumber extends HTMLAttributes<HTMLDivElement> {
  qtd: number;
}

export const TagNumber = ({ qtd, ...props }: ITagNumber) => {
  return (
    <Container {...props}>
      <Typography variant="caption" weight={700} style={{ color: "#fff" }}>
        {qtd}
      </Typography>
    </Container>
  );
};
