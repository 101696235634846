import styled from "styled-components";

export const BoxContainer = styled.div<{ size: string; flex?: boolean }>`
  display: ${({ flex }) => (flex ? "flex" : "auto")};
  align-items: ${({ flex }) => (flex ? "center" : "auto")};
  justify-content: ${({ flex }) => (flex ? "space-between" : "auto")};
  padding: 16px 24px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  flex: 1;
  width: 100%;
  height: ${({ size }) =>
    size == "small"
      ? "140px"
      : size == "medium"
      ? "412px"
      : size == "full"
      ? "100%"
      : "auto"};
  min-width: 140px;
`;
