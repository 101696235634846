import styled from "styled-components";

import {
  Typography,
  IconButton,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const IconButtonStyled = styled(IconButton)`
  && {
    &.iconButton-custom-theme-filled {
      &.MuiButtonBase-root {
        background: ${({ theme }) => theme.colors.secondary.light.light2};
        align-self: center;
      }
    }

    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
  }
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: 1;
  padding: 8px 16px;
`;

export const MainMetric = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const MetricTitle = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const TooltipIcon = styled(Icons)`
  margin-left: 4px;
  color: #53464f;
`;

export const TooltipIconButton = styled.button`
  cursor: default;
  margin-left: 4px;
`;
