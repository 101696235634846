import { GraphEmptyState } from "@Components/Graph/components/GraphEmptyState";
import {
  GenericBox,
  HeaderGraph,
  GraphOneVerticalBar,
  TGraphOneVerticalBar,
} from "@Components/index";

export type THeadcountByCity = {
  data: {
    info: { title: string; metric: string; explanation: string };
    data: TGraphOneVerticalBar["data"];
    settings: TGraphOneVerticalBar["settings"];
  };
  testCompanyId?: string;
};

export const HeadcountByCity = ({ data, testCompanyId }: THeadcountByCity) => {
  const info = data.info;
  const graphData = data.data.sort((a, b) => {
    if (a.name === "Não informado") return 1;
    if (b.name === "Não informado") return -1;

    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const settings = data.settings;
  const isEmpty = !graphData.length;

  return (
    <GenericBox size="custom">
      <HeaderGraph
        info={info}
        reportName="CITY"
        testCompanyId={testCompanyId}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphOneVerticalBar data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
