import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PageMain } from "src/pages";
import { OpenRoutesGuard } from "./openRoutesGuard";
import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";

export const baseRoute = "/analytics";

export const routes = {
  pageMain: `${baseRoute}`,
} as const;

export const slugRoutes = [
  {
    slug: "people_insights_view",
    routes: [routes.pageMain],
  },
];

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: routes.pageMain,
      hideHeader: false,
    },
  ]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<OpenRoutesGuard />}>
          <Route path={routes.pageMain} element={<PageMain />} />
        </Route>
        {/* <Route element={<RoutesGuard />}></Route> */}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
