import * as GraphVariants from "./components";

export * from "./components";

const graphs = {
  GraphTwoLinesOneBaseLine: GraphVariants.GraphTwoLinesOneBaseLine,
  GraphTwoBarsAndLine: GraphVariants.GraphTwoBarsAndLine,
  GraphPieChartEmptyCircle: GraphVariants.GraphPieChartEmptyCircle,
  GraphOneVerticalBar: GraphVariants.GraphOneVerticalBar,
  GraphStackedBar: GraphVariants.GraphStackedBar,
} as const;

type GraphProps =
  | GraphVariants.TGraphTwoLinesOneBaseLine
  | GraphVariants.TGraphTwoBarsAndLine
  | GraphVariants.TGraphPieChartEmptyCircle
  | GraphVariants.TGraphOneVerticalBar
  | GraphVariants.TGraphStackedBar;

export interface IGraph {
  graph: keyof typeof graphs;
  props: any;
}

export const Graph = ({ graph, props }: IGraph) => {
  const GraphComponent = graphs[graph];

  return GraphComponent ? (
    <GraphComponent {...props} />
  ) : (
    <div>Component not found</div>
  );
};
