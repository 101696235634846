import { DateSelect } from "@Components/Graph/components/DrawerGraphFilter";
import { GraphEmptyState } from "@Components/Graph/components/GraphEmptyState";
import {
  GenericBox,
  HeaderGraph,
  GraphTwoLinesOneBaseLine,
} from "@Components/index";

export const TurnoverMonthly = ({
  data,
  onFilterChange,
  testCompanyId,
}: {
  data: {
    info: { title: string; metric: string; explanation: string };
    data: any;
    settings: any;
    filters?: {
      dateTo?: string;
      dateFrom?: string;
    };
  };
  onFilterChange: (date: DateSelect | undefined) => void;
  testCompanyId?: string;
}) => {
  const info = data.info;
  const graphData = data.data;
  const settings = data.settings;
  const filters = data.filters;

  const isEmpty = graphData?.length == 0;

  return (
    <GenericBox size={"medium"}>
      <HeaderGraph
        info={info}
        onFiltersChange={onFilterChange}
        filters={filters}
        reportName="TURNOVER"
        testCompanyId={testCompanyId}
        disabled={isEmpty}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphTwoLinesOneBaseLine data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
