import { useState } from "react";
import { Trans } from "react-i18next";
import {
  PageHeader,
  Tab,
  Menu,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import Insights from "./tabs/Insights";
import { InsightsModal } from "./modals/InsightsModal";
import { ReportsModal } from "./modals/ReportsModal";
import { SettingsContainer, StyledFlex } from "./styled";
import { segment } from "@Utils/segment";

const PageMain = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [insightsModal, setInsightsModal] = useState(false);
  const [reportsModal, setReportsModal] = useState(false);

  const tabItens = [
    {
      label: "Insights",
      component: <Insights />,
    },
    // {
    //   label: "Relatórios",
    //   component: <Reports />,
    // },
  ];

  const insightConfig = {
    groups: [
      { name: "Colaboradores", amt: 236 },
      { name: "Freelancers", amt: 68 },
      { name: "Consultores", amt: 15 },
      { name: "Diretores", amt: 33 },
      { name: "Admitidos na última semana", amt: 12 },
    ],
  };

  return (
    <Trans i18nKey={"translations"}>
      <InsightsModal
        isOpen={insightsModal}
        closeModal={() => setInsightsModal(false)}
        config={insightConfig}
      />
      <ReportsModal
        isOpen={reportsModal}
        closeModal={() => setReportsModal(false)}
      />
      {/* <PageHeader title="Insights e Relatórios" /> */}
      <PageHeader title="Insights" />
      <SettingsContainer>
        <Menu
          type="select"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          options={[
            {
              onClick: () => setInsightsModal(true),
              children: <StyledFlex>Configurações de insights</StyledFlex>,
            },
            // {
            //   onClick: () => setReportsModal(true),
            //   children: <StyledFlex>Configurações de relatórios</StyledFlex>,
            // },
          ]}
        >
          <></>
          {/* <IconButton
              icon="IconSettings"
              size="medium"
              variant="line"
              style={{ width: "40px", height: "40px" }}
            /> */}
        </Menu>
      </SettingsContainer>
      <PageContainer>
        {/* <Tab
            tabItens={tabItens}
            selected={selectedTab}
            onTabChanged={(selected) => {
              if (selectedTab === 0) {
                segment({
                  track: "people_strategic_hr_insights_primarytab_insights_clicked",
                });
              }
              setSelectedTab(selected);
            }}
          /> */}
        <Insights />
      </PageContainer>
    </Trans>
  );
};

export default PageMain;
