export default {
  translations: {
    insights: {
      companyMainInfoTitle: "Quadro da empresa",
      companyMainInfoSubTitle:
        "Entenda a evolução do seu quadro de colaboradores",
      demographicInfoTitle: "Dados demográficos",
      demographicInfoSubTitle:
        "Entenda como a sua empresa está em relação a distribuição de gênero, etnia e localização geográfica",
    },
    reports: {
      reportTitle: "Relatórios da empresa",
      reportSubTitle: "Aqui você pode customizar e gerar relatórios",
    },
  },
};
