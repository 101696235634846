import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { FooterCancelButton, FooterContainer, SubmitButton } from "./styled";

export const Footer = ({ onClose, onSubmit, loading }: any) => {
  return (
    <FooterContainer>
      <FooterCancelButton onClick={onClose}>Cancelar</FooterCancelButton>
      <SubmitButton
        variant={"primary"}
        buttonType={"primary"}
        size={"medium"}
        style={{ margin: "auto 0", padding: "19px 52px" }}
        onClick={onSubmit}
        loading={loading}
        // disabled={disabled}
        disableRipple
        disableTouchRipple
      >
        Criar gráfico
        <Icons name="IconArrowRight" />
      </SubmitButton>
    </FooterContainer>
  );
};
