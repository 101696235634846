export const containsErrorProperty = (
  obj: any,
  path: any[] = []
): string[] | null => {
  let errorKeys: string[] = [];

  for (const key in obj) {
    if (key === "error") {
      errorKeys.push(path as any);
    } else if (typeof obj[key] === "object") {
      const result: string[] | null = containsErrorProperty(obj[key], [
        ...path,
        key,
      ]);
      errorKeys = errorKeys.concat(result || []);
    }
  }

  return errorKeys.length > 0 ? errorKeys.flat() : null;
};
