import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { TooltipIcon, TooltipIconButton, StyledTooltip } from "./styled";

export const CustomHeaderTooltip = ({ text }: any) => {
  return (
    <div>
      <StyledTooltip
        title={
          <>
            <Typography variant="body4" weight={600}>
              {text}
            </Typography>
          </>
        }
        placement="top-start"
      >
        <TooltipIconButton>
          <TooltipIcon name="IconInfoCircle" fill="transparent" size={16} />
        </TooltipIconButton>
      </StyledTooltip>
    </div>
  );
};
