import { useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";

import { ChildrenContainer, BoxContainer, LabelStyle } from "./styled";

export type TGraphPieChartEmptyCircle = {
  data: {
    value: number;
    name: string;
  }[];
  settings: {
    colorPalette: string[];
  };
};

export const GraphPieChartEmptyCircle = ({
  data,
  settings,
}: TGraphPieChartEmptyCircle) => {
  const [_activeIndex, setActiveIndex] = useState<number | undefined>(
    undefined
  );

  const onPieEnter = (_: any, index: number) => setActiveIndex(index);

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;

    return (
      <g>
        <text
          x={cx}
          y={cy - 10}
          dy={8}
          fontSize={"20px"}
          fontWeight={700}
          textAnchor="middle"
          fill={"#3B2E37"}
        >
          {Math.round(percent * 100)}% ({value})
        </text>
        <text
          x={cx}
          y={cy + 20}
          dy={8}
          fontSize={"16px"}
          fontWeight={600}
          textAnchor="middle"
          fill={"#83727D"}
        >
          {payload.name}
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle - 0.5}
          innerRadius={outerRadius - 1}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const renderStyledLengend = (value: any) => (
    <LabelStyle
      tag="span"
      variant="body4"
      style={{ color: "#6B5B66", fontWeight: 600 }}
    >
      {value}
    </LabelStyle>
  );

  return (
    <ChildrenContainer>
      <BoxContainer>
        <ResponsiveContainer width="98%" height="98%">
          <PieChart width={500} height={500}>
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={80}
              outerRadius={100}
              activeIndex={_activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter}
              onMouseLeave={() => setActiveIndex(undefined)}
            >
              {data?.map((entry: any, index: number) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={settings.colorPalette[index]}
                  />
                );
              })}
            </Pie>
            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              iconType={"circle"}
              formatter={renderStyledLengend}
            />
          </PieChart>
        </ResponsiveContainer>
      </BoxContainer>
    </ChildrenContainer>
  );
};
