import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const CardImage = styled.img`
  width: 50%;
  height: fit-content;
  margin: auto 0;
  padding-right: 40px;
`;

export const TextContainer = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledSubtitle = styled(Typography)`
  color: var(--color-neutral-50);
`;

export const StyledIcon = styled(Icons)`
  margin: auto 0;
`;
