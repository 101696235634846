import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 100px;
  background: #f20d7a;
`;
