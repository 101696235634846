import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div``;

export const SectionTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const SectionSubTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  margin-top: 4px;
`;

export const Metrics = styled(Grid)`
  margin: 32px 0px 56px;
`;
