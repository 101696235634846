import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const BoxContainer = styled.div`
  display: flex;
  margin-top: 10px;
  height: 100%;
  width: 100%;
`;

export const LabelStyle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.light.pure};
`;

export const LabelLineContainer = styled.div`
  width: auto;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.primary};
`;
