import {
  BoxContainer,
  LabelStyle,
  LabelLineStyle,
  LabelLineContainer,
  StyledComposedChart,
} from "./styled";
import {
  ResponsiveContainer,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  Tooltip,
} from "recharts";

export type TGraphTwoBarsAndLine = {
  data: {
    name: string;
    bar1: number;
    bar2: number;
    line: number;
  }[];
  settings: {
    bar1Name: string;
    bar2Name: string;
    line: string;
    bar1Color: string;
    bar2Color: string;
    lineColor: string;
    lineIsPercentage: boolean;
  };
};

export const GraphTwoBarsAndLine = ({
  data,
  settings,
}: TGraphTwoBarsAndLine) => {
  const renderCustomStyle = (props: any, place: any) => {
    const { x, y, value } = props;
    let newPlace = 2;
    if (place == 2) newPlace = 4;

    return (
      <g>
        <foreignObject x={x + newPlace} y={y - 25} width={100} height={100}>
          <LabelStyle variant="caption">{value}</LabelStyle>
        </foreignObject>
      </g>
    );
  };

  const renderCustomLineLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <>
        {value && (
          <g>
            <foreignObject x={x - 15} y={y - 30} width={34} height={22}>
              <LabelLineContainer>
                <LabelLineStyle variant="caption">
                  {value}
                  {settings.lineIsPercentage && "%"}
                </LabelLineStyle>
              </LabelLineContainer>
            </foreignObject>
          </g>
        )}
      </>
    );
  };

  const renderStyledLengend = (value: any) => (
    <LabelStyle
      tag="span"
      variant="body4"
      style={{ color: "#6B5B66", fontWeight: 600 }}
    >
      {value}
    </LabelStyle>
  );

  const CustomTooltip = ({ payload, active }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#1D161B",
            padding: " 12px 16px",
            borderRadius: "8px",
          }}
        >
          <p className="label" style={{ color: "#fff", fontWeight: 700 }}>
            {`${payload[0]?.payload?.name} `}
          </p>
          {payload.map((pld: any, i: any) => {
            return (
              <p className="label" style={{ color: "#fff" }} key={i}>
                {`${pld.name} : ${pld.value}`}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <BoxContainer>
      <ResponsiveContainer maxHeight={340}>
        <StyledComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" vertical={false} />
          <XAxis dataKey="name" />
          <Legend formatter={renderStyledLengend} />
          <YAxis hide={true} yAxisId="left-axis" domain={[0, "dataMax + 30"]} />
          <YAxis
            hide={true}
            yAxisId="right-axis"
            domain={[0, "dataMax + 30"]}
          />
          <Tooltip content={<CustomTooltip />} />

          <Bar
            dataKey="bar1"
            name={settings.bar1Name}
            barSize={20}
            fill={settings.bar1Color}
            yAxisId="left-axis"
          >
            <LabelList
              dataKey="bar1"
              content={(info) => renderCustomStyle(info, 1)}
            />
          </Bar>
          <Bar
            dataKey="bar2"
            name={settings.bar2Name}
            barSize={20}
            fill={settings.bar2Color}
            yAxisId="left-axis"
          >
            <LabelList
              dataKey="bar2"
              content={(info) => renderCustomStyle(info, 2)}
            />
          </Bar>
          <Line
            type="monotone"
            dataKey="line"
            name={settings.line}
            strokeWidth={3}
            stroke={settings.lineColor}
            yAxisId="right-axis"
          >
            <LabelList dataKey="line" content={renderCustomLineLabel} />
          </Line>
        </StyledComposedChart>
      </ResponsiveContainer>
    </BoxContainer>
  );
};
