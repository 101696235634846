import { CompanyBoard } from "./CompanyBoard";
import { Demographics } from "./Demographics";
import { Container } from "./styled";

const Insights = () => {
  return (
    <Container>
      <CompanyBoard />
      <Demographics />
    </Container>
  );
};

export default Insights;
