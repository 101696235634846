import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTheme } from "styled-components";

import {
  Container,
  HeaderSelector,
  MonthsContainer,
  StyledIcon,
  TagFilterStyled,
} from "./styled";

export const DEFAULT_LOCALE_LANGUAGE = navigator.language || "pt-BR";

function getMonthsList(
  monthStyle: Intl.DateTimeFormatOptions["month"] = "long"
) {
  const date = new Date();

  return Array.from({ length: 12 }, (_, i) => {
    date.setMonth(i);
    return date.toLocaleDateString(DEFAULT_LOCALE_LANGUAGE, {
      month: monthStyle,
    });
  });
}

const months = getMonthsList("short").map((month) =>
  month.replace(/\W/, "").toUpperCase()
);

export interface MonthCalendarProps {
  isCalendarOpen: boolean;
  setIsCalendarOpen: (checked: boolean) => void;
  setIsDayCalendarOpen?: (checked: boolean) => void;
  setSelectedDate: (
    month: string,
    year: number,
    monthIndex: number,
    day: number
  ) => void;
  disableYearSelector?: boolean;
  hideYearSelector?: boolean;
  showClearButton?: boolean;
  style?: React.CSSProperties;
  preselectedDate?: {
    month: string;
    year: number;
    monthIndex: number;
    day: number;
  };
  minDate?: { monthIndex: number; year: number };
  maxDate?: { monthIndex: number; year: number };
}

export const MonthCalendar = ({
  isCalendarOpen,
  setIsCalendarOpen,
  setSelectedDate,
  disableYearSelector,
  hideYearSelector,
  showClearButton,
  style,
  setIsDayCalendarOpen,
  preselectedDate,
  minDate,
  maxDate,
}: MonthCalendarProps) => {
  const theme = useTheme();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<{
    month: string;
    year: number;
    monthIndex: number | undefined;
  }>(preselectedDate ?? { month: "", year: 0, monthIndex: undefined });

  const nextYear = () => {
    setCurrentYear((prevYear) => prevYear + 1);
  };

  const previousYear = () => {
    setCurrentYear((prevYear) => prevYear - 1);
  };

  const isSelected = (month: string) =>
    selectedMonth.month === month && currentYear === preselectedDate?.year;

  return (
    <>
      {isCalendarOpen && (
        <Container style={style}>
          {!hideYearSelector && (
            <HeaderSelector>
              <StyledIcon
                name="IconChevronLeft"
                color={theme.colors.neutral[50]}
                fill="transparent"
                onClick={disableYearSelector ? undefined : previousYear}
                style={{
                  cursor: disableYearSelector ? undefined : "pointer",
                  visibility:
                    minDate && currentYear > minDate?.year
                      ? "visible"
                      : "hidden",
                }}
              />

              <LinkButton variant="secondary">{currentYear}</LinkButton>

              <StyledIcon
                name="IconChevronRight"
                color={theme.colors.neutral[50]}
                fill="transparent"
                onClick={disableYearSelector ? undefined : nextYear}
                style={{
                  cursor: disableYearSelector ? undefined : "pointer",
                  visibility:
                    maxDate && currentYear <= maxDate?.year
                      ? "visible"
                      : "hidden",
                }}
              />
            </HeaderSelector>
          )}

          <MonthsContainer>
            {months.map((month, index) => {
              if (
                minDate &&
                index + 1 <= minDate?.monthIndex &&
                currentYear <= minDate.year
              ) {
                return <></>;
              }

              if (
                maxDate &&
                index >= maxDate?.monthIndex &&
                currentYear >= maxDate.year
              ) {
                return <></>;
              }

              return (
                <TagFilterStyled
                  key={index}
                  onClick={() => {
                    setIsCalendarOpen(false);
                    setIsDayCalendarOpen && setIsDayCalendarOpen(true);
                    setSelectedDate(month, currentYear, index + 1, 0);
                    setSelectedMonth({
                      month,
                      year: currentYear,
                      monthIndex: index + 1,
                    });
                  }}
                  style={
                    isSelected(month)
                      ? { background: theme.colors.secondary[50] }
                      : undefined
                  }
                >
                  <Typography
                    style={isSelected(month) ? { color: "#fff" } : undefined}
                    variant="body3"
                    weight={700}
                    variantColor={theme.colors.neutral[30]}
                  >
                    {month}
                  </Typography>
                </TagFilterStyled>
              );
            })}
          </MonthsContainer>
          {showClearButton && (
            <LinkButton
              style={{ margin: "16px auto" }}
              variant="primary"
              onClick={() => {
                setSelectedDate("", 0, 0, 0);
                setSelectedMonth({ month: "", year: 0, monthIndex: 0 });
                setIsCalendarOpen(false);
              }}
            >
              <Typography variant="body3" weight={700}>
                Limpar
              </Typography>
            </LinkButton>
          )}
        </Container>
      )}
    </>
  );
};
