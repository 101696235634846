import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  // width: 320px;
  // min-height: 346px;
  // z-index: 1000;
  // box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.1);
  // background: #fff;
  // border-radius: 4px;
  // position: absolute;
  // left: 0px;
  // padding: 10px;
`;

export const HeaderSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 15px;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledIcon = styled(Icons)`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neutral[95]};
`;

export const MonthsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 auto;
  align-items: center;
`;

export const TagFilterStyled = styled.button`
  display: flex;
  flex: 1 1 80px;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 40px;
  width: 85px;
  padding: 8px 4px;
  margin-bottom: 20px;
`;
