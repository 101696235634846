import styled from "styled-components";

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Tooltip } from "@mui/material";

export const TooltipIcon = styled(Icons)`
  margin-left: 4px;
  color: #53464f;
`;

export const TooltipIconButton = styled.button`
  cursor: default;
  margin-left: 4px;
`;

export const StyledText = styled(Typography)`
  color: #fff;
`;

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltipPlacementTop {
    border-radius: 8px;
    padding: 12px 16px;
  }
`;
