import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 24px 32px;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
`;

export const Blur = styled.div`
  filter: blur(20px);
  opacity: 0.4;
`;

export const GraphName = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  margin-bottom: 40px;
  color: var(--color-neutral-40);
`;

export const TextContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: auto;
`;

export const Title = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Description = styled(Typography)`
  color: var(--color-neutral-50);
`;

export const StyledButton = styled(Button)`
  &.button-custom-variant.MuiPaper-root.MuiButtonBase-root {
    margin-top: 16px;
    background: var(--color-feedback-error-light2);
  }
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
  background: #ffecd6;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
