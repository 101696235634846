import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div``;

export const PalleteTitle = styled(Typography)`
  margin-top: 32px;
`;

export const PalleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
`;

export const PalleteRow = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 4px;

  border: 2px solid
    ${({ isSelected }) =>
      isSelected ? "var(--color-secondary-dark5)" : "var(--color-neutral-90)"};
  border-radius: 4px;

  width: 100%;
  justify-content: space-between;
`;

export const PalleteItem = styled.div<{ background: string }>`
  width: 53.1px;
  height: 32px;

  background: ${({ background }) => `#${background}`};
  border-radius: 4px;
`;
