import styled from "styled-components";
import { Modal } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledModal = styled(Modal)``;

export const Container = styled.div`
  min-width: 500px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 420px;
  text-align: center;
  margin: 0 auto;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: var(--color-neutral-dark1);
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: var(--color-neutral-50);
`;

export const Detail = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: var(--color-primary);
`;

export const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: var(--color-secondary-light2);
  display: flex;
  align-items: center;
  justify-content: center;
`;
