import { ReactNode } from "react";
import { BoxContainer } from "./styled";

export const GenericBox = (props: {
  children: ReactNode;
  size: string;
  flex?: boolean;
}) => {
  return (
    <BoxContainer size={props.size} flex={props.flex}>
      {props.children}
    </BoxContainer>
  );
};
