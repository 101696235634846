import styled from "styled-components";
import {
  Modal,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StyledModal = styled(Modal)``;

export const HeaderContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;

  border-style: none none solid none;
  border-color: var(--color-neutral-90);
  border-width: 1px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 420px;
  margin: 20px 48px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: var(--color-neutral-dark1);
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: var(--color-neutral-50);
`;

export const Container = styled.div`
  min-width: 648px;
`;

export const StepTitle = styled(Typography)`
  color: var(--color-neutral-30);
  margin-bottom: 4px;
`;

export const StepDescription = styled(Typography)`
  color: var(--color-neutral-50);
  margin-bottom: 16px;
`;

export const GraphTypeContainer = styled.div``;

export const StyledSelectField = styled(SelectField)`
  margin-bottom: 40px;
`;

export const GraphBox = styled.div<{ selected: boolean }>`
  padding: 32px;
  isolation: isolate;
  background: ${(props) =>
    props.selected ? "var(--color-secondary-light1)" : "#fff"};
  border: 1px solid ${(props) =>
    props.selected ? "var(--color-secondary-light4)" : "#e0e0e0"}};
  border-radius: 12px;
  flex: 1;
  width: 312px;
  height: 218px;
  min-width: 140px;
`;

export const GraphWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const GraphBoxIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const GraphBoxTitle = styled(Typography)`
  color: var(--color-neutral-30);
  font-weight: 700 !important;
  margin-bottom: 8px;
`;

export const GraphBoxDescription = styled(Typography)`
  color: var(--color-neutral-50);
`;

export const GraphIcon = styled.div`
  width: 48px;
  height: 48px;
  background: var(--color-secondary-light2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
