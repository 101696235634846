import { useState } from "react";
import { palletes } from "./palletes";
import {
  Container,
  PalleteItem,
  PalleteRow,
  PalleteTitle,
  PalleteWrapper,
} from "./styled";

export const PreparedPalettes = () => {
  const [selectedPallete, setSelectedPallete] = useState<string[]>();

  return (
    <Container>
      {palletes?.map((p, i) => {
        return (
          <PalleteWrapper key={i}>
            <PalleteTitle variant="body3">{p.name}</PalleteTitle>
            {p?.items?.map((row, i) => {
              return (
                <PalleteRow
                  key={i}
                  onClick={() => setSelectedPallete(row)}
                  isSelected={row[0] === selectedPallete?.[0]}
                >
                  {row?.map((item, i) => {
                    return <PalleteItem key={i} background={item} />;
                  })}
                </PalleteRow>
              );
            })}
          </PalleteWrapper>
        );
      })}
    </Container>
  );
};
