import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxContainer = styled.div`
  display: flex;
  margin-top: 10px;
  height: 240px;
  width: 100%;
  overflow-x: auto;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LabelStyle = styled(Typography)`
  margin-top: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;
