import { GenericBox } from "@Components/GenericBox";
import TurnoverImage from "@Assets/turnover-redirect-card.png";
import {
  CardImage,
  Container,
  StyledIcon,
  StyledSubtitle,
  TextContainer,
} from "./styled";
import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const RedirectCard = () => {
  return (
    <GenericBox size="full">
      <Container>
        <CardImage src={TurnoverImage} alt="turnover" />
        <TextContainer>
          <Typography variant="headline6">Turnover de funcionários:</Typography>
          <StyledSubtitle variant="body2">
            Entenda os impactos na empresa
          </StyledSubtitle>
          <Button
            size="medium"
            variant="secondary"
            onClick={() =>
              window.open(
                "https://blog.flashapp.com.br/turnover-de-funcionarios"
              )
            }
          >
            Saiba mais
            <StyledIcon name="IconArrowRight" />
          </Button>
        </TextContainer>
      </Container>
    </GenericBox>
  );
};
