import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import {
  BoxContainer,
  IconButtonStyled,
  MetricsContainer,
  MetricTitle,
  MainMetric,
} from "./styled";
import { CustomHeaderTooltip } from "@Components/CustomHeaderTooltip";

export const SmallNumberInfo = (props: {
  info: {
    title: string;
    value: number;
    icon?: IconTypes;
    explanation: string;
  };
}) => {
  const info = props.info;

  return (
    <BoxContainer>
      <IconButtonStyled variant="filled" icon={info.icon} size="medium" />
      <MetricsContainer>
        <MetricTitle variant="body3" style={{ fontWeight: 600 }}>
          {info.title}
        </MetricTitle>
        <MainMetric variant="headline5">{info.value}</MainMetric>
      </MetricsContainer>
      <CustomHeaderTooltip text={info.explanation} />
    </BoxContainer>
  );
};
