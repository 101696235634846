import { GraphEmptyState } from "@Components/Graph/components/GraphEmptyState";
import {
  GenericBox,
  HeaderGraph,
  GraphOneVerticalBar,
  TGraphOneVerticalBar,
} from "@Components/index";

export type THeadcountByDepartment = {
  data: {
    info: { title: string; metric: string; explanation: string };
    data: TGraphOneVerticalBar["data"];
    settings: TGraphOneVerticalBar["settings"];
  };
  testCompanyId?: string;
};

export const HeadcountByDepartment = ({
  data,
  testCompanyId,
}: THeadcountByDepartment) => {
  const info = data.info;
  const graphData = data.data;
  const settings = data.settings;

  const isEmpty = !graphData.length;

  return (
    <GenericBox size="full">
      <HeaderGraph
        info={info}
        reportName="DEPARTMENT"
        testCompanyId={testCompanyId}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphOneVerticalBar data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
