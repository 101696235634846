import { useState } from "react";

import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledSubTitle,
  StyledContainer,
  StyledButtonContainer,
  CloseButton,
  HeaderArea,
  SuccessMessage,
} from "./styled";
import { getFromLS } from "@flash-tecnologia/hros-web-utility";

export const ModalExportSuccess = ({ open, onClose }: any) => {
  const { attributes = null } = getFromLS("userAuth");

  const handleClose = () => {
    onClose();
  };

  return (
    <StyledModal onClose={handleClose} open={open}>
      <>
        <HeaderArea>
          <StyledIconContainer>
            <StyledIcon size={48} name={"IconCheck"} fill="transparent" />
          </StyledIconContainer>
          <SuccessMessage variant="body3">Pronto!</SuccessMessage>
          <CloseButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={handleClose}
          />
        </HeaderArea>
        <StyledContainer>
          <StyledTitle variant="headline6">
            Seu relatório está sendo exportado!
          </StyledTitle>
          <StyledSubTitle variant="body3">
            Para acessá-lo é só conferir o e-mail que será enviado em
            <br />
            alguns minutos para o endereço:
            <br />
            <b>{attributes?.email}</b>
            <br />
            <br />
            Para acessar os dados utilize os{" "}
            <b>
              4 primeiros dígitos do <br />
              seu CPF
            </b>{" "}
            como senha.
          </StyledSubTitle>
        </StyledContainer>
        <StyledButtonContainer>
          <Button size="large" variant="primary" onClick={handleClose}>
            Voltar para Insights
          </Button>
        </StyledButtonContainer>
      </>
    </StyledModal>
  );
};
