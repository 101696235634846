import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  /* padding-bottom: 0px; */
  padding: 16px 72px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Text = styled(Typography)`
  text-align: center;
`;

export const EmptyStateIconBox = styled.img``;
