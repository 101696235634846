import { Modal } from "@flash-tecnologia/hros-web-ui-v2";

export const ReportsModal = ({ isOpen, closeModal }: any) => {
  return (
    <Modal open={isOpen} onClose={() => closeModal()}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>Relatórios!</span>
        <span>Em breve</span>
      </div>
    </Modal>
  );
};
