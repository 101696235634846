import { useState } from "react";

import {
  IconButton,
  IconTypes,
  Icons,
  Radio,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./Footer";
import {
  HeaderContainer,
  StyledModal,
  TextWrapper,
  Title,
  Subtitle,
  Container,
  StepTitle,
  StepDescription,
  StyledSelectField,
  GraphBox,
  GraphWrapper,
  GraphBoxIconsWrapper,
  GraphBoxTitle,
  GraphBoxDescription,
  GraphIcon,
} from "./styled";

interface ModalCreateGraphInterface {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const graphs = [
  {
    id: 1,
    name: "Gráfico de barras",
    description:
      "Compare dados de múltiplas categorias. Quanto maior for o número exibido, mais larga será a barra.",
    icon: "IconChartBar",
  },
  {
    id: 2,
    name: "Gráfico de rosca",
    description:
      "Compare dados de múltiplas categorias. Quanto maior for o número exibido, mais larga será a barra.",
    icon: "IconChartDonut",
  },
];

export const ModalCreateGraph = ({
  isOpen,
  loading,
  onClose,
  onSubmit,
}: ModalCreateGraphInterface) => {
  const [selectedGraph, setSelectedGraph] = useState<number | undefined>(
    undefined
  );

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer onClose={onClose} onSubmit={onSubmit} loading={loading} />
      }
      header={
        <HeaderContainer>
          <TextWrapper>
            <Title>Criar novo gráfico</Title>
            <Subtitle>
              Crie gráficos personalizados a partir dos dados demográficos da
              sua empresa.
            </Subtitle>
          </TextWrapper>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
          />
        </HeaderContainer>
      }
    >
      <Container>
        <StepTitle variant="headline8">Selecionar dados</StepTitle>
        <StepDescription variant="body4">
          Escolha as informações que deverão refletir no gráfico
        </StepDescription>
        <StyledSelectField
          options={[]}
          label="Selecione o tipo de dado"
          onSelectChange={() => {
            // onChange(value?.value)
          }}
          // value={value}
          // error={error}
          // helperText={helperText}
        />
        <StepTitle variant="headline8">Escolher gráfico</StepTitle>
        <StepDescription variant="body4">
          Selecione o formato desejado para o gráfico:
        </StepDescription>
        <GraphWrapper>
          {graphs?.map((graph) => (
            <GraphBox
              key={graph.id}
              onClick={() => setSelectedGraph(graph.id)}
              selected={graph.id === selectedGraph}
            >
              <GraphBoxIconsWrapper>
                <GraphIcon>
                  <Icons
                    name={graph.icon as IconTypes}
                    fill="transparent"
                    color="#F20D7A"
                  />
                </GraphIcon>
                <Radio checked={graph.id === selectedGraph} />
              </GraphBoxIconsWrapper>
              <GraphBoxTitle variant="body3">{graph.name}</GraphBoxTitle>
              <GraphBoxDescription variant="body4">
                {graph.description}
              </GraphBoxDescription>
            </GraphBox>
          ))}
        </GraphWrapper>
      </Container>
    </StyledModal>
  );
};
