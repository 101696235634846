import { MonthCalendar } from "@Components/MonthCalendar";
import {
  Accordion,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Box, Drawer } from "@mui/material";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { segment } from "@Utils/segment";
import { TagNumber } from "@Components/TagNumber";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
`;

const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;

  padding: 16px 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: auto;
`;

const FooterCancelButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: flex-end;

  text-decoration: underline;

  text-underline-offset: 10px;

  color: ${({ theme }) => theme.colors.primary};

  margin: 16px 0;
`;

export type DateSelect = {
  month: string;
  day: number;
  monthIndex: number;
  year: number;
};

export const DrawerGraphFilter = ({
  onDateSelect,
  activeDate,
  disabled,
}: {
  onDateSelect: (date: DateSelect | undefined) => void;
  activeDate: DateSelect | undefined;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<DateSelect | undefined>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleRedefine = () => {
    onDateSelect(undefined);
    setSelectedDate(undefined);
    handleClose();
  };

  const onSubmit = () => {
    segment({
      track: "people_strategic_hr_insights_filter_button_clicked",
    });
    onDateSelect(selectedDate);
    setDrawerOpen(false);
  };

  const handleOpen = () => {
    segment({
      track: "people_strategic_hr_insights_chart_filterdata_button_clicked",
    });
    setDrawerOpen(true);
  };

  const handleDateSelect = ({ month, year, monthIndex, day }: DateSelect) => {
    segment({
      track: "people_strategic_hr_insights_period_dropdown_clicked",
    });
    setSelectedDate({ month, year, monthIndex, day });
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { width: "50%" },
        }}
      >
        <Box padding="40px">
          <Box
            display="flex"
            justifyContent="flex-end"
            margin="27px 27px 0 0"
            position="absolute"
            right={0}
            top={0}
          >
            <IconButton
              variant="line"
              size="small"
              icon="IconX"
              onClick={handleClose}
            />
          </Box>
          <Typography
            variant="headline7"
            variantColor={theme.colors.neutral[10]}
          >
            Filtros
          </Typography>
        </Box>
        <Divider orientation="horizontal" />
        <Content>
          <Accordion
            variant="default"
            title="Período"
            defaultExpanded
            children={
              <MonthCalendar
                isCalendarOpen={true}
                setIsCalendarOpen={() => null}
                setSelectedDate={(
                  month: string,
                  year: number,
                  monthIndex: number,
                  day: number
                ) => handleDateSelect({ month, year, monthIndex, day })}
                preselectedDate={selectedDate}
                // Data minima é Abril de 2023 pois foi quando People começou a vender em mar aberto
                minDate={{ monthIndex: 3, year: 2023 }}
                maxDate={{
                  monthIndex: new Date().getMonth() + 1,
                  year: new Date().getFullYear(),
                }}
              />
            }
          />
        </Content>
        <FooterContainer>
          <FooterCancelButton onClick={handleRedefine}>
            Redefinir
          </FooterCancelButton>
          <Button
            variant={"primary"}
            size={"small"}
            style={{
              margin: "auto 0",
              padding: "19px 52px",
            }}
            onClick={onSubmit}
            loading={false}
            disableRipple
            disableTouchRipple
          >
            Filtrar
          </Button>
        </FooterContainer>
      </Drawer>
      <IconButton
        variant="line"
        icon={"IconAdjustmentsHorizontal"}
        size="small"
        onClick={handleOpen}
        disabled={disabled}
      >
        {!!selectedDate && (
          <TagNumber qtd={1} style={{ right: "-4px", top: "-2px" }} />
        )}
      </IconButton>
    </>
  );
};
