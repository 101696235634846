import styled from "styled-components";
import { Typography, Modal } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledModal = styled(Modal)`
  && {
    .modal-content-area {
      padding: 24px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalContainer = styled.div`
  // display: flex;
  // flex-direction: column;
  overflow: scroll;
`;

export const ModalTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.dark.dark1};
`;

export const ModalSubTitle = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const BoxContainer = styled.div`
  padding: 24px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  flex: 1;
  width: 100%;
  margin-top: 24px;
`;

export const SearchContainer = styled.div`
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const PositionRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PositionColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const PeopleAndGroupTitle = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const PeopleAndGroupSubTitle = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const CheckboxItem = styled.div`
  margin-top: 10px;
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckboxLabel = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const AmountLabel = styled(Typography)`
  margin-left: auto;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const ToggleTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const ToggleSubTitle = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const BoxTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
