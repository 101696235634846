import { useTranslation } from "react-i18next";
import dispatchToast from "@Utils/dispatchToast";
import { Grid } from "@mui/material";

import {
  HeadcountByDepartment,
  HeadcountByState,
  HeadcountByRace,
  AgeDistributionByGender,
  HeadcountByGender,
  HeadcountByCity,
} from "./Graphs";

import { Container, SectionTitle, SectionSubTitle, Metrics } from "./styled";
import { CardCreateGraph } from "./CardCreateGraph";
import { ModalCreateGraph } from "./ModalCreateGraph";
import { useMemo, useState } from "react";
import { LowFidelity } from "@Components/LowFidelity";
import { graphs } from "./data";
import { trpc } from "src/api/client";
import { containsErrorProperty } from "@Utils/containstErrorProperty";
import { useTheme } from "styled-components";

function moveItemToFront(arr: any[], prop: string, value: string | number) {
  const index = arr.findIndex((item) => item?.[prop] === value);

  if (index !== -1 && index !== 0) {
    const [item] = arr.splice(index, 1);
    arr.unshift(item);
  }
}

const defaultValues = {
  employeesByAgeGender: [],
  employeesByDepartment: [],
  employeesByState: [],
  employeesByCity: [],
  employeesByRace: [],
  employeesByGender: [],
};

const defaultValuesKeys = Object.keys(defaultValues);

export const Demographics = () => {
  const theme = useTheme();
  const [t] = useTranslation("translations", { keyPrefix: "insights" });
  const [modalCreateGraphOpen, setModalCreateGraphOpen] = useState(false);

  const { data } = trpc.getPeopleDemographicsData.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const testCompanyId = data?.testCompany;

  const errorPath = containsErrorProperty(data);

  type NonUndefinedData = NonNullable<typeof data>;

  type DemographicsType = {
    [K in keyof NonUndefinedData]: Exclude<
      NonUndefinedData[K],
      { error: string }[]
    >;
  };

  const dynamicValues: DemographicsType = defaultValuesKeys.reduce(
    (result, key) => {
      result[key] = ((data as any)?.[key] as any)?.error
        ? undefined
        : ((data as any)?.[key] as any);
      return result;
    },
    {} as any
  );

  const {
    employeesByAgeGender = [],
    employeesByDepartment = [],
    employeesByState = [],
    employeesByCity = [],
    employeesByRace = [],
    employeesByGender = [],
  } = dynamicValues;

  const graphsData = useMemo(() => {
    const uniqueEmployeeAgeGenders = new Set();

    employeesByAgeGender.forEach((ageGender) =>
      uniqueEmployeeAgeGenders.add(ageGender.age)
    );

    const employeeAgeGender = Array.from(uniqueEmployeeAgeGenders).map(
      (ageGender) => {
        const dataItem = {
          name: ageGender as string,
          Masculino: 0,
          Feminino: 0,
          Outros: 0,
          ["Não informado"]: 0,
          ["Não declarado"]: 0,
          Total: 0,
        };

        employeesByAgeGender.forEach((item) => {
          if (item.age === ageGender) {
            if (item.gender === "Masculino")
              dataItem.Masculino = item.employee_count;
            if (item.gender === "Feminino")
              dataItem.Feminino = item.employee_count;
            if (item.gender === "Outros") dataItem.Outros = item.employee_count;
            if (item.gender === "Não informado")
              dataItem["Não informado"] = item.employee_count;
            if (item.gender === "Não declarado")
              dataItem["Não declarado"] = item.employee_count;
          }
        });

        dataItem.Total =
          dataItem.Masculino +
          dataItem.Feminino +
          dataItem["Não informado"] +
          dataItem["Não declarado"] +
          dataItem.Outros;

        return dataItem;
      }
    );

    moveItemToFront(employeesByGender, "gender", "Não informado");

    return {
      employeesByAgeGender: employeeAgeGender || [],
      employeesByDepartment:
        employeesByDepartment?.map((item) => ({
          name: item.department_name,
          amt: item.employee_count,
        })) || [],
      employeesByState:
        employeesByState?.map((item) => ({
          name: item.state,
          amt: item.employee_count,
        })) || [],
      employeesByCity:
        employeesByCity?.map((item) => ({
          name: item.city,
          amt: item.employee_count,
        })) || [],
      employeesByRace:
        employeesByRace?.map((item) => ({
          name: item.race,
          value: item.employee_count,
        })) || [],
      employeesByGender:
        employeesByGender?.map((item) => ({
          name: item.gender,
          value: item.employee_count,
        })) || [],
      theme,
    };
  }, [
    employeesByAgeGender,
    employeesByCity,
    employeesByDepartment,
    employeesByGender,
    employeesByRace,
    employeesByState,
  ]);

  defaultValuesKeys.forEach((key) => {
    if (errorPath && errorPath.includes(key)) {
      const dataItem: any = [...graphs(graphsData as any)].find(
        (item) => item.id === key
      );

      const dataName = !!dataItem?.title
        ? dataItem.title
        : dataItem?.info.title;

      if (dataName)
        dispatchToast({
          type: "error",
          content: `Sentimos muito, ocorreu um erro ao buscar os dados de "${dataName}"`,
        });
    }
  });

  return (
    <>
      <Container>
        <SectionTitle variant="headline8">
          {t("demographicInfoTitle")}
        </SectionTitle>
        <SectionSubTitle variant="body3">
          {t("demographicInfoSubTitle")}
        </SectionSubTitle>
        <Metrics container>
          {graphs(graphsData)?.map((graph: any, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              lg={graph?.info?.fieldName == "idade" ? 12 : 6}
              key={index}
              padding={"0 16px 16px 0"}
            >
              {/* <LowFidelity
                name={graph.info.title}
                active={graph.info.lowFidelity}
                fieldName={graph.info.fieldName}
              > */}
              {(() => {
                switch (index) {
                  case 0:
                    return (
                      <HeadcountByDepartment
                        data={graph}
                        testCompanyId={testCompanyId}
                      />
                    );
                  case 1:
                    return (
                      <HeadcountByState
                        data={graph}
                        testCompanyId={testCompanyId}
                      />
                    );
                  case 2:
                    return (
                      <HeadcountByCity
                        data={graph}
                        testCompanyId={testCompanyId}
                      />
                    );
                  case 3:
                    return (
                      <HeadcountByGender
                        data={graph}
                        testCompanyId={testCompanyId}
                      />
                    );
                  // case 3:
                  //   return <HeadcountByRace data={graph} />;
                  case 5:
                    return (
                      <AgeDistributionByGender
                        data={graph}
                        testCompanyId={testCompanyId}
                      />
                    );
                  default:
                    return <></>;
                }
              })()}
              {/* </LowFidelity> */}
            </Grid>
          ))}
          {/* <Grid item xs={12} sm={6} lg={6}>
            <CardCreateGraph openModal={() => setModalCreateGraphOpen(true)} />
          </Grid> */}
        </Metrics>
      </Container>
      <ModalCreateGraph
        isOpen={modalCreateGraphOpen}
        loading={false}
        onClose={() => setModalCreateGraphOpen(false)}
        onSubmit={() => null}
      />
    </>
  );
};
