import { DefaultTheme } from "styled-components";

export const graphs = ({
  employeesByAgeGender = [],
  employeesByDepartment = [],
  employeesByState = [],
  employeesByCity = [],
  employeesByRace = [],
  employeesByGender = [],
  theme,
}: {
  employeesByDepartment: { name: string; amt: number }[];
  employeesByState: { name: string; amt: number }[];
  employeesByCity: { name: string; amt: number }[];
  employeesByRace: { name: string; value: number }[];
  employeesByAgeGender: {
    name: string;
    Masculino: number;
    Feminino: number;
  }[];
  employeesByGender: { value: number; name: string }[];
  theme: DefaultTheme;
}) =>
  [
    {
      id: "employeesByDepartment",
      info: {
        title: "Headcount por Departamento",
        fieldName: "departamento",
        metric: "",
        explanation:
          "Número de pessoas de cada departamento da empresa. Apenas colaboradores com o status ativo são contabilizados.",
        lowFidelity: false,
      },
      data: employeesByDepartment,
      settings: { name: "quantidade", color: "#FE2B8F", showAll: false },
    },
    {
      id: "employeesByState",
      info: {
        title: "Headcount por UF",
        fieldName: "state",
        metric: "",
        explanation:
          "Número de pessoas que moram em cada unidade federativa do Brasil. Apenas colaboradores com o status ativo são contabilizados.",
        lowFidelity: false,
      },
      data: employeesByState,
      settings: {
        name: "quantidade",
        color: "#FE2B8F",
        showAll: true,
      },
    },
    {
      id: "employeesByCity",
      info: {
        title: "Headcount por cidade",
        fieldName: "city",
        metric: "",
        explanation:
          "Número de pessoas que moram em cada cidade. Apenas colaboradores com o status ativo são contabilizados.",
        lowFidelity: true,
      },
      data: employeesByCity,
      settings: {
        name: "quantidade",
        color: "#FE2B8F",
        showAll: true,
      },
    },
    {
      id: "employeesByGender",
      info: {
        title: "Headcount por Identidade de Gênero",
        fieldName: "identidade de gênero",
        metric: "",
        explanation:
          "Número de pessoas de cada gênero dentro do headcount da empresa. Apenas colaboradores com o status ativo são contabilizados.",
        lowFidelity: true,
      },
      data: employeesByGender,
      settings: {
        colorPalette: [
          // Não informado is first index
          theme.colors.neutral[60],
          theme.colors.secondary[30],
          theme.colors.secondary[50],
          theme.colors.secondary[80],
          theme.colors.secondary[90],
        ],
      },
    },
    {
      id: "employeesByRace",
      info: {
        title: "Headcount pela cor de pele",
        fieldName: "cor de pele",
        metric: "",
        explanation: "Explicação aqui do gráfico",
        lowFidelity: false,
      },
      data: employeesByRace,
      settings: {
        colorPalette: [
          "#78003C",
          "#AA0955",
          "#DA0B6D",
          "#FE2B8F",
          "#FFCCE4",
          "#FFE0EF",
        ],
      },
    },
    {
      id: "employeesByAgeGender",
      info: {
        title: "Distribuição etária por gênero",
        fieldName: "idade",
        metric: "",
        explanation:
          "Número de pessoas de cada gênero por faixas de idade empresa. Apenas colaboradores com o status ativo são contabilizados.",
        lowFidelity: false,
      },
      data: employeesByAgeGender,
      settings: [
        {
          name: "Masculino",
          color: theme.colors.secondary[30],
        },
        {
          name: "Feminino",
          color: theme.colors.secondary[50],
        },
        {
          name: "Outros",
          color: theme.colors.secondary[80],
        },
        {
          name: "Não declarado",
          color: theme.colors.secondary[90],
        },
        {
          name: "Não informado",
          color: theme.colors.neutral[60],
        },
      ],
    },
  ] as const;
