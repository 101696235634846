import styled from "styled-components";

export const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 37px;
  margin-top: -71px;
  margin-bottom: 50px;
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;
