import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import {
  HeadcountTotalAmount,
  HeadcountTotalAdmission,
  HeadcountTotalResignation,
  HeadcountEvolution,
  TurnoverMonthly,
  AverageServiceTime,
} from "./Graphs";

import { Container, SectionTitle, SectionSubTitle, Metrics } from "./styled";
import { trpc } from "src/api/client";
import { cards, graphs } from "./data";
import dispatchToast from "@Utils/dispatchToast";
import { useMemo, useState } from "react";
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { containsErrorProperty } from "@Utils/containstErrorProperty";
import { RedirectCard } from "./RedirectCard";

const defaultValues = {
  headcount: 0,
  admissions: 0,
  resignations: 0,
  headcountEvolution: [],
  averageServiceTime: [],
  turnover: [],
};

const defaultValuesKeys = Object.keys(defaultValues);

const dateFilterString = (year: number, month: number, day: number) => {
  return `${year}-${month}-${day}`;
};

function dateFromTo(year: number | undefined, monthIndex: number | undefined) {
  if (!year || !monthIndex) {
    return {
      dateFrom: "",
      dateTo: "",
    };
  }

  const firstDate = new Date(year, monthIndex, 1);

  const currentMonth = new Date().getMonth() + 1;
  const currentMonthLastDay = new Date(year, currentMonth, 0).getDate();

  return {
    dateFrom: dateFilterString(year, monthIndex, firstDate.getDate()),
    dateTo: dateFilterString(
      new Date().getFullYear(),
      currentMonth,
      currentMonthLastDay
    ),
  };
}

export const CompanyBoard = () => {
  const [filters, setFilters] = useState({
    headcountEvolution: { dateFrom: "", dateTo: "" },
    turnover: { dateFrom: "", dateTo: "" },
  });

  const [t] = useTranslation("translations", { keyPrefix: "insights" });

  const { data } = trpc.getPeopleCompanyBoardData.useQuery({
    headcountEvolutionQueryParams: filters.headcountEvolution,
    turnoverQueryParams: filters.turnover,
  });

  const testCompanyId = data?.testCompany;

  const errorPath = containsErrorProperty(data);

  type NonUndefinedData = NonNullable<typeof data>;

  type CompanyDataType = {
    [K in keyof NonUndefinedData]: Exclude<
      NonUndefinedData[K],
      { error: string }[]
    >;
  };

  const dynamicValues: CompanyDataType = defaultValuesKeys.reduce(
    (result, key) => {
      result[key] = ((data as any)?.[key] as any)?.error
        ? undefined
        : ((data as any)?.[key] as any);
      return result;
    },
    {} as any
  );

  const {
    headcount = 0,
    admissions = 0,
    resignations = 0,
    headcountEvolution = [],
    averageServiceTime = [],
    turnover = [],
  } = dynamicValues;

  const cardsData = useMemo(() => {
    return {
      headcount: headcount as number,
      admissions: admissions as number,
      resignations: resignations as number,
    };
  }, [headcount, admissions, resignations]);

  const graphsData = useMemo(() => {
    return {
      headcountEvolution: headcountEvolution?.map((item) => {
        const monthName = dayjs(item?.date)
          .add(1, "month")
          .toDate()
          .toLocaleDateString("pt-BR", { month: "short" });

        return {
          name: monthName,
          bar1: item?.admissions,
          bar2: item?.resignations,
          line: item?.headcount,
        };
      }),
      averageServiceTime: [
        {
          name: "Não informado",
          amt:
            averageServiceTime?.find((item) => item.quarter == "Não informado")
              ?.qtde ?? 0,
        },
        {
          name: "Até 3 meses",
          amt:
            averageServiceTime?.find((item) => item.quarter == "Até 3 meses")
              ?.qtde ?? 0,
        },
        {
          name: "3 - 6 meses",
          amt:
            averageServiceTime?.find((item) => item.quarter == "3 - 6 meses")
              ?.qtde ?? 0,
        },
        {
          name: "6 - 9 meses",
          amt:
            averageServiceTime?.find((item) => item.quarter == "6 - 9 meses")
              ?.qtde ?? 0,
        },
        {
          name: "9 - 12 meses",
          amt:
            averageServiceTime?.find((item) => item.quarter == "9 - 12 meses")
              ?.qtde ?? 0,
        },
        {
          name: "12 - 15 meses",
          amt:
            averageServiceTime?.find((item) => item.quarter == "12 - 15 meses")
              ?.qtde ?? 0,
        },
        {
          name: "15+ meses",
          amt:
            averageServiceTime?.find((item) => item.quarter == "15+ meses")
              ?.qtde ?? 0,
        },
      ],
      turnover: turnover.map((item: any) => {
        return {
          name: dayjs(
            `${item.year}-${item.month.toString().padStart(2, "0")}-01`
          )
            .toDate()
            .toLocaleDateString("pt-BR", { month: "short" }),
          line: Number(item.turnover.toFixed(1)),
        };
      }),
    };
  }, [headcountEvolution, averageServiceTime, turnover]);

  defaultValuesKeys.forEach((key) => {
    if (errorPath && errorPath.includes(key)) {
      const dataItem: any = [
        ...cards(cardsData),
        ...graphs(graphsData, filters),
      ].find((item) => item.id === key);

      const dataName = !!dataItem?.title
        ? dataItem.title
        : dataItem?.info.title;

      if (dataName)
        dispatchToast({
          type: "error",
          content: `Sentimos muito, ocorreu um erro ao buscar os dados de "${dataName}"`,
        });
    }
  });

  return (
    <Container>
      <SectionTitle variant="headline8">
        {t("companyMainInfoTitle")}
      </SectionTitle>
      <SectionSubTitle variant="body3">
        {t("companyMainInfoSubTitle")}
      </SectionSubTitle>
      <Metrics container>
        {cards(cardsData)?.map((card, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            key={index}
            padding={"0 16px 16px 0"}
          >
            {(() => {
              switch (index) {
                case 0:
                  return <HeadcountTotalAmount data={card} />;
                case 1:
                  return <HeadcountTotalAdmission data={card} />;
                case 2:
                  return <HeadcountTotalResignation data={card} />;
                default:
                  return <></>;
              }
            })()}
          </Grid>
        ))}
        {graphs(graphsData, filters)?.map((graph, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={6}
            key={index}
            padding={"0 16px 16px 0"}
          >
            {(() => {
              switch (index) {
                case 0:
                  return (
                    <HeadcountEvolution
                      testCompanyId={testCompanyId}
                      data={graph}
                      onFilterChange={(date) => {
                        setFilters({
                          ...filters,
                          headcountEvolution: dateFromTo(
                            date?.year,
                            date?.monthIndex
                          ),
                        });
                      }}
                    />
                  );
                case 1:
                  return (
                    <TurnoverMonthly
                      testCompanyId={testCompanyId}
                      data={graph}
                      onFilterChange={(date) => {
                        setFilters({
                          ...filters,
                          turnover: dateFromTo(date?.year, date?.monthIndex),
                        });
                      }}
                    />
                  );
                case 2:
                  return (
                    <AverageServiceTime
                      data={graph}
                      testCompanyId={testCompanyId}
                    />
                  );
                default:
                  return <></>;
              }
            })()}
          </Grid>
        ))}
        <Grid item xs={12} sm={6} lg={6} padding={"0 16px 16px 0"}>
          <RedirectCard />
        </Grid>
      </Metrics>
    </Container>
  );
};
