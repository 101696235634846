export const palletes = [
  {
    name: "Paletas mistas",
    items: [
      [
        "29066C",
        "532096",
        "7D39C0",
        "AF4BCF",
        "D950BA",
        "EC548D",
        "EA637B",
        "E97369",
        "F0A58E",
        "FBE3DD",
      ],
      [
        "0C1B4B",
        "29538F",
        "3375B1",
        "5F95C9",
        "C6D1F1",
        "F7C4D9",
        "DE6F86",
        "CA435C",
        "9D2B46",
        "721532",
      ],
      [
        "142458",
        "16487D",
        "186BA1",
        "19ABDE",
        "1AC9E6",
        "1BD5D4",
        "1CDDC8",
        "1DE4BD",
        "6DF0D1",
        "C7F9EE",
      ],
      [
        "224066",
        "325F98",
        "4682C1",
        "589BC1",
        "69B4C1",
        "89C494",
        "AFD66C",
        "CEE455",
        "D7ED4F",
        "D8F44F",
      ],
      [
        "384B59",
        "29618D",
        "4C978B",
        "C5DE46",
        "F4BC4D",
        "E89A9E",
        "A561B5",
        "D15542",
        "A04730",
        "CA7B60",
      ],
      [
        "445545",
        "A7C691",
        "D3DBA2",
        "35A994",
        "4D858A",
        "646180",
        "9D5D70",
        "D55860",
        "F64D60",
        "F3AF89",
      ],
      [
        "E94675",
        "A34697",
        "644D9F",
        "5263AE",
        "0974BB",
        "1BA0DB",
        "50BEBF",
        "B4D338",
        "F3BB1E",
        "F47822",
      ],
      [
        "BD4B31",
        "BB271A",
        "ECB576",
        "F9DA78",
        "9DC384",
        "80A4AE",
        "789DE5",
        "5083C1",
        "634FA2",
        "B87E9E",
      ],
      [
        "810400",
        "BF2324",
        "CE3B29",
        "DE542D",
        "EF7E32",
        "F19A37",
        "EABE3B",
        "E7E34E",
        "EFEC86",
        "F7F4BF",
      ],
      [
        "06494B",
        "278287",
        "79C7BE",
        "BCE4D1",
        "F3F6D7",
        "FDD597",
        "F3AC5A",
        "DB8635",
        "BB6526",
        "9A451B",
      ],
    ],
  },
  {
    name: "Paletas monocromáticas",
    items: [
      [
        "283036",
        "3A464E",
        "596973",
        "677783",
        "7B8E98",
        "91A4AE",
        "AFBEC4",
        "D0D8DC",
        "DBE1E6",
        "EDEFF1",
      ],
      [
        "8100BF",
        "A200F1",
        "B334F5",
        "BB4EF2",
        "C767F7",
        "D07FF8",
        "D99AFD",
        "E3B3FC",
        "ECCCFB",
        "F3E6FC",
      ],
      [
        "DB1A72",
        "F51D7F",
        "F74A9B",
        "F860A3",
        "F976B2",
        "FA8EBD",
        "FBA6CC",
        "FDBAD8",
        "FDD1E5",
        "FEE8F2",
      ],
      [
        "1D55B3",
        "297AFF",
        "5593FE",
        "67A0FD",
        "7FAFFF",
        "95BCFF",
        "A9CAFE",
        "BED7FF",
        "D4E4FE",
        "E9F0FE",
      ],
      [
        "007ECC",
        "009DFE",
        "33B1FF",
        "4CB9FF",
        "68C5FF",
        "7FCDFD",
        "9AD8FE",
        "B2E1FF",
        "CCEBFE",
        "E4F5FF",
      ],
      [
        "018C79",
        "01C0A6",
        "33CDB6",
        "4DD1C0",
        "66D8C9",
        "80DFD1",
        "98E5DB",
        "B3EBE6",
        "CDF2ED",
        "E5F8F6",
      ],
      [
        "59C414",
        "64DD17",
        "83E444",
        "93E75D",
        "A4EB76",
        "B1EE8C",
        "C2F1A3",
        "D0F5B9",
        "E0F8D0",
        "EFFCE8",
      ],
      [
        "E69A00",
        "FFAB00",
        "FEBC33",
        "FFC54D",
        "FFCE66",
        "FFD47F",
        "FFDD99",
        "FEE5B2",
        "FFEECE",
        "FFF6E5",
      ],
      [
        "D95F01",
        "FF7001",
        "FF8B33",
        "FF9A4D",
        "FEAA67",
        "FFB581",
        "FFC599",
        "FFD3B1",
        "FFE3CD",
        "FFF0E5",
      ],
      [
        "E60000",
        "FF0000",
        "FF3434",
        "FF4C4C",
        "FF6565",
        "FF7F7F",
        "FE9A9A",
        "FFB2B2",
        "FFCCCC",
        "FFE6E6",
      ],
    ],
  },
];
